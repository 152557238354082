






























































































import { Component, PropSync, Ref, Vue, Watch } from 'vue-property-decorator'
import CampoDecimal from '@/components/ui/CampoDecimal.vue'
import { FindGrupoEconomicoUseCase, SaveGrupoEconomicoUseCase } from '@/usecases'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import {  obrigatorio } from '@/shareds/regras-de-form'
import { criarGrupoEconomico } from '@/shareds/grupo-economico-shareds'
import { GrupoEconomico } from '@/models/GrupoEconomico'
import { Loja } from '@/models'
import UserLoginStore from '@/store/vuex/authentication/UserLoginStore'
import { montarOperacaoDeRecurso } from '@/shareds/permissoes-shareds'

@Component({
	components: {
		CampoDecimal,
	},
})
export default class DialogoDeEdicaoDeGrupoEconomico extends Vue {
	@PropSync('value', { type: Object }) grupoEconomicoAnterior?: GrupoEconomico
	@Ref() form!: HTMLFormElement
	saveGrupoEconomicoUseCase = new SaveGrupoEconomicoUseCase()
	findGrupoEconomicoUseCase = new FindGrupoEconomicoUseCase()

	grupoEconomico: GrupoEconomico = criarGrupoEconomico()
	mostra = false
	salvando = false
	lojas: Loja[] = []
	obrigatorio = obrigatorio

	get podeAlterarGrupoEconomico() {
		return UserLoginStore.temAutorizacao(montarOperacaoDeRecurso('alterar', 'grupos-economicos'))
	}

	get podeDeletarGrupoEconomico() {
		return UserLoginStore.temAutorizacao(montarOperacaoDeRecurso('deletar', 'grupos-economicos'))
	}

	get podeCriarGrupoEconomico() {
		return UserLoginStore.temAutorizacao(montarOperacaoDeRecurso('criar', 'grupos-economicos'))
	}

	get temAcessoIrrestrito() {
		return UserLoginStore.permiteRegraDeNegocio('acesso-irrestrito')
	}

	mostrar(){
		this.mostra = true
	}

	@Watch('mostra')
	onChangeMostra(mostra) {
		if (!mostra) return
		if (this.grupoEconomicoAnterior) {
			if (mostra) {
				this.buscarLojas()
			}

			this.grupoEconomico = JSON.parse(JSON.stringify(this.grupoEconomicoAnterior))
		} else {
			this.grupoEconomico = criarGrupoEconomico()
		}
	}

	async buscarLojas() {
		if (!this.grupoEconomicoAnterior) return

		this.lojas = await this.findGrupoEconomicoUseCase.findLojasByGrupoEconomico(this.grupoEconomicoAnterior.id)
	}
	
	async salvar(){
		if (!this.grupoEconomico) return
		if (!this.form.validate()) return
		try {
			this.salvando = true
			const salvarGruposEconomicos = !this.grupoEconomico.id
				? await this.saveGrupoEconomicoUseCase.create(this.grupoEconomico)
				: await this.saveGrupoEconomicoUseCase.update(this.grupoEconomico)

			this.$emit('confirmado', salvarGruposEconomicos)
			AlertModule.setSuccess('Grupo Econômico salvo com sucesso')
		} catch(error) {
			AlertModule.setError(error.response.data[0])
		} finally {
			this.mostra = false
			this.salvando = false
		}
	}
}

